import { signingIn } from '../firebase/auth';
import { goto } from './navigation';
import { get, set } from './storage';

export function checkoutPlan(subscriber: string | null, channelId: string, plan: number) {
  return async () => {
    if (!subscriber) {
      await signingIn();
    }

    let sessionId = get('checkout-session-id');

    if (sessionId === undefined) {
      sessionId = crypto.randomUUID();
      set('checkout-session-id', sessionId, 900);
    }

    await goto(`/checkout?subscriber=${subscriber}&channelId=${channelId}&plan=${plan}&sessionId=${sessionId}`);
  };
}
